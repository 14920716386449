import { addWeeks, format, isValid, parseISO } from 'date-fns';

const MS_IN_SECOND = 1000;
const SEC_IN_MINUTE = 60;
const BACKEND_DATE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";

export const monthNames = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь'
];

export const quarterNames = [
  'I квартал',
  'II квартал',
  'III квартал',
  'IV квартал'
];

export function parseApiDate(date) {
  const d = parseISO(date);
  if (!isValid(d) || date.startsWith('000')) {
    return null;
  }
  return d;
}

export function removeTimezoneFromDate(date) {
  if (!date) return null;
  const dt = new Date(date);
  return new Date(
    dt.valueOf() + dt.getTimezoneOffset() * SEC_IN_MINUTE * MS_IN_SECOND
  );
}

export function addWeekToDateString(date) {
  const dateObj = parseApiDate(date);
  if (!dateObj) return null;
  return addWeeks(dateObj, 1);
}

export function dateToBackendString(date) {
  return format(date, BACKEND_DATE_FORMAT);
}

export function getMonth(monthNumber) {
  return monthNames[monthNumber];
}

export function getCurrentYear() {
  return new Date().getFullYear();
}

export function getCurrentMonth() {
  return new Date().getMonth() + 1;
}

export function getCurrentDay() {
  return new Date().getDate();
}

export const yearsSuggestions = [
  { value: '2019', label: 2019 },
  { value: '2020', label: 2020 },
  { value: '2021', label: 2021 },
  { value: '2022', label: 2022 },
  { value: '2023', label: 2023 },
  { value: '2024', label: 2024 },
  { value: '2025', label: 2025 }
];

export const subsidiaryEntitiesYearsSuggestions = [
  { value: '2015', label: 2015 },
  { value: '2016', label: 2016 },
  { value: '2017', label: 2017 },
  { value: '2018', label: 2018 },
  { value: '2019', label: 2019 },
  { value: '2020', label: 2020 },
  { value: '2021', label: 2021 },
  { value: '2022', label: 2022 },
  { value: '2023', label: 2023 },
  { value: '2024', label: 2024 },
  { value: '2025', label: 2025 }
];

export const monthsSuggestions = monthNames.map((m, iM) => ({
  label: m,
  value: iM + 1
}));
