import React from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  input: {
    minWidth: 160,
    marginRight: 20
  }
}));

const years = [2019, 2020, 2021, 2022, 2023, 2024, 2025];

export const YearSelect = React.memo(function YearSelect({
  years: yearsProps,
  year,
  onChange,
  label,
  maxYear,
  variant = 'outlined',
  className,
  ...props
}) {
  const classes = useStyles();
  const handleChange = (event) => {
    onChange({ year: event.target.value }, true);
  };

  return (
    <TextField
      select
      value={year}
      onChange={handleChange}
      className={classNames(classes.input, className)}
      variant={variant}
      label={label}
      {...props}
    >
      {(yearsProps || years)
        .filter((year) => !maxYear || year <= maxYear)
        .map((year) => (
          <MenuItem key={year} value={year}>
            {year}
          </MenuItem>
        ))}
    </TextField>
  );
});
