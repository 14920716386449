export const useCreateYearsSuggestions = (ssYear, year) => {
  if (!ssYear) return [{ value: year, label: year }];
  const count = 2025 - ssYear + 1;
  const yearsSuggestions = [];
  for (let i = 0; i < count; i++) {
    yearsSuggestions.push({
      value: ssYear,
      label: ssYear
    });
    ssYear++;
  }
  return yearsSuggestions;
};
